import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { AdmissionsService } from '../../services/admissions.service';
import {
  ApproveAdmission,
  ChangePatientSigner,
  ChangePatientSignerWithoutLogin,
  CreateAdmissionFromAttachment,
  CurrentCaregiver,
  DeclineAdmission,
  DocumentsListAdmission,
  GetAdmissionById,
  GetAdmissionByToken,
  GetAdmissionDepartments,
  GetAdmissionPayers,
  GetAdmissionStates,
  GetRatings,
  GetRelationships,
  GetReligions,
  GetStatuses,
  LoadAdmission,
  RemoveCurrentCaregiver,
  RemoveDCWFromPOC,
  RemoveDraftPOC,
  ResetFormValueAndDischargeSummery,
  ResetSignatureVerificationAndConfirmationErrors,
  ResetState,
  SaveAdmission,
  SaveDcwOnAdmission,
  SaveDraftPOC,
  SaveScheduleDaysVisit,
  SetAction,
  SetButtonOptions,
  SetFirstSignShowArrowAdmission,
  SetPatientSigner,
  SetPatientSignerWithoutUser,
  SetPOCDate,
  SetSignatureVerificationAndConfirmationErrors,
  SetSigned,
  SetSignedWithoutLogin,
  SetStarted,
  SignDocument,
  SignDocumentWithoutLogin,
} from './admission-form.actions';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { StaticDataService } from '../../../static-data.service';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AdmissionTypeEnum } from '../../../shared/enums/admission.enum';
import { DischargeSummary } from './admission-model';
import { ButtonsOptions } from '../../admission-application/interfaces';
import { MessagePopupComponent } from '../../../popups/message-popup/message-popup.component';
import { SignaturePopupComponent } from '../../../popups/signature-popup/signature-popup.component';
import { UpdateProfileUserSign } from '../users/users.actions';
import { PopupFactoryService } from '../../../popups/popup-factory.service';

interface AdmissionFormStateModel {
  formValue: any;
  listAdmissionDocuments: any;
  relationships: any[];
  ratings: any[];
  religions: any[];
  states: any[];
  statuses: any;
  list: any[];
  payers: Array<{ id: string; title: string }>;
  createAdmissionFromAttachmentPayload: any;
  error: any;
  load: boolean;
  updateFirstSignShowArrowAdmission?: boolean;
  config: {
    isCanBeComplete: boolean;
    currentId: string;
    currentPatientId: string;
    currentPatientName: string;
    currentTypeOfApplication: AdmissionTypeEnum;
    currentStatus: number;
  };
  buttonsOptions: ButtonsOptions;
  dischargeSummary: DischargeSummary | object;
  action: {
    name: string;
    data: any;
  };
  currenCaregivers: any;
  draftPOC: {
    patient?: any;
    caregivers?: any;
    competencyRequirementsTraining?: any;
    changePOC?: any;
  };
}

export interface Payers {
  id: number;
  title: string;
  checked: boolean;
}

const defaultState: any = {
  formValue: {},
  listAdmissionDocuments: [],
  relationships: [],
  ratings: [],
  religions: [],
  states: [],
  statuses: {},
  list: [],
  createAdmissionFromAttachmentPayload: null,
  error: {},
  updateFirstSignShowArrowAdmission: false,
  load: false,
  config: {
    isCanBeComplete: false,
    currentId: '',
    currentPatientId: '',
    currentPatientName: '',
    currentTypeOfApplication: AdmissionTypeEnum.Admission,
    currentStatus: null,
  },
  dischargeSummary: {},
  buttonsOptions: {
    disableAutoSave: true,
    disableGenerateDocuments: true,
    disableCreateWithAttach: true,
    disableApprove: false,
    disabledSentToOffice: false,
    disabledCancelDS: false,
  },
  currenCaregivers: [],
  draftPOC: {},
};

@State({
  name: 'admissionForm',
  defaults: defaultState,
})
@Injectable()
export class AdmissionFormState {
  constructor(
    private service: AdmissionsService,
    private staticData: StaticDataService,
    private popup: PopupFactoryService,
  ) {}

  @Selector()
  static isCanBeComplete(state) {
    return state.config.isCanBeComplete;
  }

  @Selector()
  static currentTypeOfApplication(state) {
    return state.config.currentTypeOfApplication;
  }

  @Selector()
  static currentStatus(state) {
    return state.config.currentStatus;
  }

  @Selector()
  static currentCaregivers(state) {
    return state.currenCaregivers;
  }

  @Selector()
  static draftPOC(state) {
    return state.draftPOC;
  }

  @Selector()
  static createAt(state) {
    return state.createdAt;
  }

  @Selector()
  static action(state) {
    return state.action;
  }

  @Selector()
  static list(state) {
    return state.list;
  }

  @Selector()
  static payers(state) {
    return state.payers;
  }

  @Selector()
  static formValue(state) {
    return state.formValue;
  }

  @Selector()
  static dischargeSummary(state) {
    return state.dischargeSummary;
  }

  @Selector()
  static error(state) {
    return state.error;
  }

  @Selector()
  static listSentDocument(state) {
    return state.formValue.patientSigner.documentsKeys;
  }

  @Selector()
  static caregivers(state) {
    return state.formValue.haveCriminal.caregivers;
  }

  @Select()
  static listAdmissionDocuments(state) {
    return state.listAdmissionDocuments;
  }

  @Selector()
  static relationships(state) {
    return state.relationships;
  }

  @Selector()
  static relationshipsForApp(state) {
    return state.relationships.filter(i => !i.showOnlyDocumentSign);
  }

  @Selector()
  static ratings(state) {
    return state.ratings;
  }

  @Selector()
  static religions(state) {
    return state.religions;
  }

  @Selector()
  static states(state) {
    return state.states;
  }

  @Selector()
  static config(state) {
    return state.config;
  }

  @Selector()
  static createAdmissionFromAttachmentPayload(state) {
    return state.createAdmissionFromAttachmentPayload;
  }

  @Selector()
  static updateFirstSignShowArrowAdmission(state) {
    return state.updateFirstSignShowArrowAdmission;
  }

  @Selector()
  static buttonsOptions(state) {
    return state.buttonsOptions;
  }

  @Selector()
  static dischargeSummarySaving(state) {
    return state.buttonsOptions.disabledCancelDS;
  }

  @Action(GetAdmissionById)
  getById(ctx: StateContext<AdmissionFormStateModel>, { id, discharge }): Observable<any> {
    if (discharge) {
      ctx.patchState({
        dischargeSummary: {},
      });
    }
    return this.service.getById(id, discharge).pipe(
      tap((data: any) => {
        if (!discharge) {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient?.state?.id,
              },
            },
            config: {
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentPatientName: data.patient.firstName + ' ' + data.patient.lastName,
              currentPatientId: data.patient.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        } else {
          ctx.patchState({
            dischargeSummary: {
              ...data,
            },
            config: {
              currentPatientName: data.consumer.name,
              currentPatientId: data.patient.id,
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        }
      }),
    );
  }

  @Action(GetAdmissionByToken)
  getAdmissionByToken(ctx: StateContext<AdmissionFormStateModel>, { token }: GetAdmissionByToken): Observable<any> {
    return this.service.getAdmissionInfoByToken(token).pipe(
      tap((data: any) => {
        if (data.admissionType === 3) {
          ctx.patchState({
            dischargeSummary: {
              ...data,
            },
            config: {
              currentPatientName: data.consumer.name,
              currentPatientId: data.patient.id,
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        } else {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient.state?.id,
              },
            },
            config: {
              currentPatientName: data.patient.firstName + ' ' + data.patient.lastName,
              currentPatientId: data.patient.id,
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        }
      }),
    );
  }

  @Action(GetAdmissionStates)
  getStates(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getStates().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          states: data,
        });
      }),
    );
  }

  @Action(GetRelationships)
  getRelationships(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getRelationships().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          relationships: data,
        });
      }),
    );
  }

  @Action(GetReligions)
  getReligions(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getReligions().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          religions: data,
        });
      }),
    );
  }

  @Action(SaveAdmission)
  saveAdmission(ctx: StateContext<AdmissionFormStateModel>, { payload }: SaveAdmission): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    const discharge: boolean =
      state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary ||
      state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummaryInt;
    ctx.patchState({
      buttonsOptions: {
        ...state.buttonsOptions,
        disabledCancelDS: true,
      },
    });
    return this.service.autoSave(state.config.currentId, payload, discharge).pipe(
      tap((data: any) => {
        if (discharge) {
          ctx.patchState({
            dischargeSummary: {
              ...data,
            },
            config: {
              currentPatientName: data.consumer.name,
              currentPatientId: data.patient.id,
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        } else {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient.state.id,
              },
            },
            config: {
              currentPatientName: data.patient.firstName + ' ' + data.patient.lastName,
              currentPatientId: data.patient.id,
              isCanBeComplete: data.isCanBeComplete,
              currentId: data.id,
              currentTypeOfApplication: data.admissionType,
              currentStatus: data.status.id,
            },
          });
        }
      }),
      catchError(err => {
        ctx.patchState({
          formValue: {
            ...state.formValue,
            isCanBeComplete: false,
          },
          config: {
            ...state.config,
            isCanBeComplete: false,
          },
        });
        return throwError(err);
      }),
      finalize(() => {
        ctx.patchState({
          buttonsOptions: {
            ...state.buttonsOptions,
            disabledCancelDS: false,
          },
        });
      }),
    );
  }

  @Action(ApproveAdmission)
  approveAdmission(ctx: StateContext<AdmissionFormStateModel>): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.approve(state.config.currentId);
  }

  @Action(DeclineAdmission)
  declineAdmission(ctx: StateContext<AdmissionFormStateModel>, { comment, withSigns }: DeclineAdmission): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.decline(state.config.currentId, comment, withSigns);
  }

  @Action(GetRatings)
  getRating(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getRatings().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          ratings: data,
        });
      }),
    );
  }

  @Action(SetStarted)
  setStarted(ctx: StateContext<AdmissionFormStateModel>, { dataSend }: SetStarted): Observable<any> {
    const state = ctx.getState();
    return this.service.setStarted(state.config.currentId, dataSend).pipe(
      catchError(err => {
        if (err.status === 422) {
          {
            this.popup
              .createPopup({
                popupComponent: MessagePopupComponent,
                preventBgClick: true,
                popupData: {
                  message: 'Please, add your signature in profile settings',
                  closeRoute: null,
                  yellowHeader: true,
                },
              })
              .subscribe(() => {
                setTimeout(() => {
                  this.popup
                    .createPopup({
                      popupComponent: SignaturePopupComponent,
                      title: 'Leave your signature',
                      hideIcon: true,
                    })
                    .pipe(take(1))
                    .subscribe(({ data }) => {
                      if (data) {
                        ctx.dispatch(new UpdateProfileUserSign(data));
                      }
                    });
                });
              });
          }
        }
        return throwError(err);
      }),
    );
  }

  @Action(GetStatuses)
  getStatuses(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.service.getStatuses().pipe(
      tap((data: any[]) => {
        ctx.patchState({
          statuses: data?.reduce((acc, curr) => ({ ...acc, [curr.title]: curr.id }), {}),
        });
      }),
    );
  }

  @Action(SetPatientSigner)
  setPatientSigner(ctx: StateContext<AdmissionFormStateModel>, { representativeObject }: SetPatientSigner): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.setPatientSigner(state.formValue.id, representativeObject);
  }

  @Action(SetPatientSignerWithoutUser)
  setPatientSignerWithoutUser(
    ctx: StateContext<AdmissionFormStateModel>,
    { token, representativeObject }: SetPatientSignerWithoutUser,
  ): Observable<any> {
    return this.service.setPatientSignerWithoutUser(token, representativeObject);
  }

  @Action(ChangePatientSigner)
  changePatientSigner(ctx: StateContext<AdmissionFormStateModel>, { id, payload }: ChangePatientSigner): Observable<any[]> {
    const admId = id ? id : ctx.getState().formValue.id;
    return this.service.setPatientSigner(admId, payload).pipe(
      tap(data => {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data?.patient,
              stateId: data?.patient?.state?.id,
            },
          },
        });
      }),
    );
  }

  @Action(ChangePatientSignerWithoutLogin)
  changePatientSignerWithoutLogin(
    ctx: StateContext<AdmissionFormStateModel>,
    { payload, token }: ChangePatientSignerWithoutLogin,
  ): Observable<any[]> {
    return this.service.setPatientSignerWithoutUser(token, payload).pipe(
      tap(data => {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data?.patient,
              stateId: data?.patient?.state?.id,
            },
          },
        });
      }),
    );
  }

  @Action(SetSignatureVerificationAndConfirmationErrors)
  setSignatureVerificationAndConfirmationErrors(ctx: StateContext<AdmissionFormStateModel>, { errorKey }): void {
    const state: AdmissionFormStateModel = ctx.getState();
    state.error[errorKey] = 'The relationship field is required when sign is present.';
    ctx.patchState({
      error: state.error,
    });
  }

  @Action(ResetSignatureVerificationAndConfirmationErrors)
  resetSignatureVerificationAndConfirmationErrors(ctx: StateContext<AdmissionFormStateModel>): void {
    ctx.patchState({
      error: {},
    });
  }

  @Action(SignDocument)
  signDocument(ctx: StateContext<AdmissionFormStateModel>, { signs, documentKey }: SignDocument): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    let isDischarge = false;
    if (
      state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary ||
      state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummaryInt
    ) {
      isDischarge = true;
    }
    return this.service.signDocument(state.config.currentId, documentKey, signs, isDischarge).pipe(
      tap((data: any) => {
        if (
          state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummary ||
          state.config.currentTypeOfApplication === AdmissionTypeEnum.DischargeSummaryInt
        ) {
          ctx.patchState({
            dischargeSummary: {
              ...data,
            },
          });
        } else {
          ctx.patchState({
            formValue: {
              ...data,
              patient: {
                ...data.patient,
                stateId: data.patient.state.id,
              },
            },
          });
        }
      }),
      catchError((err: any) => {
        ctx.patchState({
          error: err.error?.violations || {},
        });
        return throwError(err);
      }),
    );
  }

  @Action(SignDocumentWithoutLogin)
  signDocumentWithoutLogin(ctx: StateContext<AdmissionFormStateModel>, { token, signs, documentKey }: SignDocumentWithoutLogin): Observable<any> {
    return this.service.signDocumentWithoutLogin(token, documentKey, signs).pipe(
      tap((data: any) => {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data.patient,
              stateId: data.patient.state.id,
            },
          },
        });
      }),
      catchError((err: any) => {
        ctx.patchState({
          error: err.error.violations || {},
        });
        return throwError(err);
      }),
    );
  }

  @Action(SetSigned)
  setSigned(ctx: StateContext<AdmissionFormStateModel>): Observable<any> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.setSigned(state.config.currentId).pipe(take(1));
  }

  @Action(SetSignedWithoutLogin)
  setSignedWithoutLogin(ctx: StateContext<AdmissionFormStateModel>, { token, isEmployee }: SetSignedWithoutLogin): Observable<any> {
    return this.service.setSignedWithoutLogin(token, isEmployee);
  }

  @Action(SaveScheduleDaysVisit)
  saveScheduleDaysVisit(ctx: StateContext<AdmissionFormStateModel>, { payload }: SaveScheduleDaysVisit): Observable<any> {
    return this.service.saveScheduleDaysVisit(payload);
  }

  @Action(CreateAdmissionFromAttachment)
  createAdmissionFromAttachment(ctx: StateContext<AdmissionFormStateModel>, { payload }: CreateAdmissionFromAttachment): Observable<any> {
    ctx.patchState({
      createAdmissionFromAttachmentPayload: payload,
    });
    return this.service.createAdmissionFromAttachment(payload);
  }

  @Action(DocumentsListAdmission)
  documentsListAdmission(ctx: StateContext<AdmissionFormStateModel>, { id }: DocumentsListAdmission): Observable<any> {
    return this.service.getDocumentAdmissionTypes(id).pipe(
      tap((data: any): void => {
        ctx.patchState({
          listAdmissionDocuments: data.listAdmissionDocuments,
        });
      }),
    );
  }

  @Action(GetAdmissionDepartments)
  getAdmissionDepartments(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getDepartments().pipe(tap((data: any[]) => ctx.patchState({ list: data })));
  }

  @Action(GetAdmissionPayers)
  getAAdmissionPayers(ctx: StateContext<AdmissionFormStateModel>): Observable<any[]> {
    return this.staticData.getPayers().pipe(tap((data: any[]) => ctx.patchState({ payers: data })));
  }

  @Action(LoadAdmission)
  removeAdmissionId(ctx: StateContext<AdmissionFormStateModel>, { load }): void {
    ctx.patchState({
      load,
    });
  }

  @Action(SetFirstSignShowArrowAdmission)
  setFirstSignShowArrowAdmission(ctx: StateContext<AdmissionFormStateModel>): void {
    ctx.patchState({
      updateFirstSignShowArrowAdmission: !ctx.getState().updateFirstSignShowArrowAdmission,
    });
  }

  @Action(SetButtonOptions)
  setButtonOptions(ctx: StateContext<AdmissionFormStateModel>, { save }): void {
    const state: AdmissionFormStateModel = ctx.getState();
    ctx.patchState({
      buttonsOptions: {
        ...state.buttonsOptions,
        ...save,
      },
    });
  }

  @Action(SetAction)
  setAction(ctx: StateContext<AdmissionFormStateModel>, { action }): void {
    ctx.patchState({
      action: {
        ...action,
      },
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<AdmissionFormStateModel>): void {
    ctx.patchState({
      action: {
        name: '',
        data: '',
      },
      buttonsOptions: {
        disableAutoSave: true,
        disableGenerateDocuments: true,
        disableCreateWithAttach: true,
        disableApprove: false,
        disabledSentToOffice: false,
        disabledCancelDS: false,
      },
    });
  }

  @Action(ResetFormValueAndDischargeSummery)
  resetFormValueAndDischargeSummery(ctx: StateContext<AdmissionFormStateModel>): void {
    ctx.patchState({
      formValue: {},
      dischargeSummary: {},
    });
  }

  @Action(SetPOCDate)
  setPOCdate(ctx: StateContext<AdmissionFormStateModel>, { id, date, key }): Observable<void> {
    return this.service.setPOCDate(id, date, key);
  }

  @Action(CurrentCaregiver)
  currentCaregiver(ctx: StateContext<AdmissionFormStateModel>, { caregiver }): void {
    ctx.patchState({
      currenCaregivers: caregiver,
    });
  }

  @Action(RemoveCurrentCaregiver)
  removeCurrentCaregiver(ctx: StateContext<AdmissionFormStateModel>): void {
    ctx.patchState({
      currenCaregivers: {},
    });
  }

  @Action(SaveDcwOnAdmission)
  saveDcw(ctx: StateContext<AdmissionFormStateModel>, { payload }): Observable<void> {
    const state: AdmissionFormStateModel = ctx.getState();
    return this.service.saveDcw(payload, state.formValue.id).pipe(
      tap((data: any) => {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data.patient,
              stateId: data.patient.state.id,
            },
          },
        });
      }),
    );
  }

  @Action(RemoveDCWFromPOC)
  removeDcwFromPOC(ctx: StateContext<AdmissionFormStateModel>, { admissionId, DCWUID }): Observable<void> {
    return this.service.removeDCWFromPOC(admissionId, DCWUID).pipe(
      tap((data: any) => {
        ctx.patchState({
          formValue: {
            ...data,
            patient: {
              ...data.patient,
              stateId: data.patient.state.id,
            },
          },
        });
      }),
    );
  }

  @Action(SaveDraftPOC)
  saveDraftPOC(ctx: StateContext<AdmissionFormStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.patchState({
      draftPOC: {
        caregivers: payload.caregivers || state.draftPOC.caregivers,
        competencyRequirementsTraining: payload.competencyRequirementsTraining || state.draftPOC.competencyRequirementsTraining,
        changePOC: payload.changePOC || state.draftPOC.changePOC,
        patient: payload.patient || state.draftPOC.patient,
      },
    });
  }

  @Action(RemoveDraftPOC)
  removeDraftPOC(ctx: StateContext<AdmissionFormStateModel>) {
    ctx.patchState({
      draftPOC: {
        caregivers: [],
      },
    });
  }
}
